import * as devops from 'devops-fe';

const { requests } = devops;

requests.interceptors.request.use((config) => {
  try {
    config.headers['Accept-Language'] = JSON.parse(localStorage.getItem('language'));
  }
  catch {
    config.headers['Accept-Language'] = navigator.language === 'zh-CN' ? 'zh-hans' : 'en';
  }
  return config;
});

export default devops;
