import devops from 'src/devops';

const {
  constant,
} = devops;

export const PubFields = constant.JSEnum([
  // { label: '业务区', value: 'corp', key: 'CORP' },
  // { label: '环境', value: 'env', key: 'ENV' },
  // { label: '服务树路径', value: 'path', key: 'PATH' },
  // { label: 'AppKey', value: 'appkey', key: 'APP_KEY' },
]);

export const OpenClose = constant.JSEnum([
  { label: '开', value: true },
  { label: '关', value: false },
]);

export const DEFAULT_DATE_TIME_FMT = 'YYYY-MM-DD HH:ss';
