import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import resources from 'public/assets/locales/resources.js';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

let localLang = '';
try {
  localLang = JSON.parse(localStorage.getItem('language'));
} catch {
  localLang = '';
}
if (!localLang) {
  localLang = navigator.language === 'zh-CN' ? 'zh-CN' : 'en-US';
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localLang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'zh',  // 如果检测不到语言，默认使用中文
    debug: true,    // 本地调试，查看语言检测的详细日志
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
