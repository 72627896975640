import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';

import 'antd/dist/antd.css';
import 'devops-fe/dist/static/index.css';
import './index.less';

import zhCN from 'antd/es/locale/zh_CN';
import * as serviceWorker from './serviceWorker';

import devops from './devops';
import routers from './router/index';
import './i18n';


ReactDOM.render(
  <ConfigProvider locale={zhCN} componentSize='small'>
    <devops.App routers={routers}/>
  </ConfigProvider>, document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
