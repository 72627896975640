import enUS from './en-US.json';
import zh from './zh-CN.json';
var resources = {
  'en-US': {
    translation: enUS
  },
  'en': {
    translation: enUS
  },
  'zh-CN': {
    translation: zh
  },
  'zh': {
    translation: zh
  }
};
export default resources;