// 便于统一维护，之后好统一清理前端缓存
const PREFIX_V1 = 'demo_';

/* 记录一些存储key，统一在这里维护 */
const KeyBuilder = {
  // 通用参数记录
  PUB_ARGS: `${PREFIX_V1}_pub_args`,
};

export default KeyBuilder;
