import React, { useEffect, useState } from 'react';
import {
  Affix,
  Layout,
} from 'antd';
import { useLocation } from 'react-router-dom';
import 'mwheels-design/dist/index.css';
import 'src/styles/ant-patch.less';
import { PikeMenu } from 'mwheels-design';
import routers from 'src/router/index';
import 'src/styles/main.less';
import { isMailDomain } from 'src/common/utils';
import ArgsContext, { useQueryArgs } from 'src/common/hooks/ArgsContext';


const findRouteName = (location, route) => {
  if (!route.path) {
    return null;
  }
  if (route.path === location.pathname || (location.name && location.name === route.name)) {
    return route.name;
  }
  if (route.routes) {
    for (let i = 0; i < route.routes.length; i++) {
      const item = route.routes[i];
      const _name = findRouteName(location, item);
      if (_name) {
        return _name;
      }
    }
  }
  return null;
};

export default function Main(props) {
  const location = useLocation();
  const [pubManager, routeQuery, pubArgs] = useQueryArgs();

  const [curRouteKey, setCurRouteKey] = useState();

  useEffect(() => {
    const info = location.pathname.split('/').filter(item => item);
    if (info.length > 1) {
      const name = findRouteName(location, { path: '/', routes: routers });
      setCurRouteKey(name);
    }
    // 发生路由改变的时候，滑动到最上面
    window.scrollTo(0, 0);
  }, [location]);

  const { children } = props;

  return (
    <Layout>
      <Affix>
        <Layout.Sider className='devops-slider-cls'>
          <PikeMenu
            key='demo'
            menuConfig={{
              system: 'IT员工管理',
              navigations: isMailDomain() ? [] : [
                {
                  label: '任务列表',
                  key: 'task_msg',
                  show: true,
                  link_to: { name: 'task_msg', query: {} },
                },
                {
                  label: '推送消息',
                  key: 'push_msg',
                  show: true,
                  link_to: { name: 'push_msg', query: {} },
                },
                {
                  label: 'VPN二维码',
                  key: 'vpn_qr',
                  show: true,
                  link_to: { name: 'vpn_qr', query: {} },
                },
              ],
            }}
            antMenuConf={{
              defaultOpenKeys: null,
              selectedKeys: [curRouteKey],
            }}
          />
        </Layout.Sider>
      </Affix>
      <Layout.Content className='devops-layout-content'>
        <ArgsContext.Provider value={{ pubManager, routeQuery, pubArgs }}>
          {children}
        </ArgsContext.Provider>
      </Layout.Content>
    </Layout>
  );
}
