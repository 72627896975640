import React, { useState, useEffect, useContext, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { dequal as deepEqual } from 'dequal';

import KeyBuilder from 'src/common/builder';
import { PubFields } from 'src/common/constant';

import devops from 'src/devops';

const { tools, storage, hooks: { updateStateReducer } } = devops;
const { useSessionStorage } = storage;
/**
 * 全局通用参数
 */
const ArgsContext = React.createContext({
  routeQuery: {},  // 当前路由参数
  pubManager: {}, // 用于更新通用参数的 和一些其他方法
  pubArgs: {},  // 当前公共参数
  cacheData: {},
});

/**
 * TODO Deprecated 仅用来支持 不是函数式 组件时使用
 * @param props
 * @returns {*}
 * @constructor
 */
export function ArgsManager(props) {
  const { routeQuery, pubArgs } = useContext(ArgsContext);

  // 回调函数
  const {
    onRouteQueryChange,
    onArgsChange,
  } = props;

  useEffect(() => {
    if (typeof onArgsChange === 'function') {
      onArgsChange(pubArgs);
    }

  }, [pubArgs]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof onRouteQueryChange === 'function') {
      onRouteQueryChange(routeQuery);
    }
  }, [routeQuery]);  // eslint-disable-line react-hooks/exhaustive-deps

  return props.children;
}

// 其他控件不要使用useQueryArgs, 请使用ArgsContext
export function useQueryArgs() {
  const location = useLocation();

  const [routeQuery, setRouteQuery] = useState({});
  const [pubArgs, setPubArgs] = useState({});
  const [cacheData, setCacheData] = useReducer(updateStateReducer, {});

  const [traceArgs, setTraceArgs] = useSessionStorage(KeyBuilder.PUB_ARGS, {});

  useEffect(() => {
    const { search } = location;
    const query = tools.queryString.parse(search);
    setRouteQuery(preQuery => {
      if (deepEqual(preQuery, query)) {
        return preQuery;
      }
      return query;
    });

    // 处理使用过的参数
    setTraceArgs((prevArgs) => {
      const traceCache = { ...prevArgs };
      PubFields.forEach(field => {
        if (query[field.value]) {
          traceCache[field.value] = query[field.value];
        }
      });
      if (!deepEqual(prevArgs, traceCache)) {
        return traceCache;
      }
      return prevArgs;
    });

    setPubArgs(preArgs => {
      const tmpArgs = { ...pubArgs };
      PubFields.forEach(field => {
        tmpArgs[field.value] = query[field.value];
      });
      if (deepEqual(preArgs, tmpArgs)) {
        return preArgs;
      }
      return tmpArgs;
    });
  }, [location.search]);  // eslint-disable-line react-hooks/exhaustive-deps


  const pubManager = {
    traceArgs,
    cacheData,
    setCacheData,
  };

  return [pubManager, routeQuery, pubArgs];
}

export default ArgsContext;
