import React from 'react';
import loadable from '@loadable/component';

import Main from 'src/component/Main';
// import MainHelp from 'src/component/help/MainHelp';
import { isMailDomain } from 'src/common/utils';
import devops from 'src/devops';

const { NotFound, MainAdmin, RouteForMain, LoginView } = devops;

const FindView = loadable(() => import('src/component/FindView'));
const MailLogin = loadable(() => import('src/component/MailLogin'));
const SysMain = loadable(() => import('src/component/base/SysMain'));
const PushMsg = loadable(() => import('src/component/tools/PushMsg'));
const TaskMsg = loadable(() => import('src/component/tools/TaskMsg'));
const VPNView = loadable(() => import('src/component/tools/VPNView'));

// const TestEditForm = loadable(() => import('src/component/demo/TestEditForm'));


const routers = isMailDomain() ? [
  { type: 'Redirect', exact: true, from: '/', to: 'mail/login' },
  // 定义的后缀 和 LoginViewType 一一对应
  { path: '/mail/:viewType', exact: true, name: 'mail_login', component: MailLogin },
  // 因为组件库的原因，有用到 route name，没有配置会报错
  { name: 'user_list', path: 'user/list', component: NotFound },
  { name: 'not_found', path: '*', exact: true, component: NotFound },
] : [
  { type: 'Redirect', exact: true, from: '/', to: 'main/tools/task-msg' },
  // { type: 'Redirect', exact: true, from: '/', to: 'sys/admin/kvconfig/list' },
  { path: '/login', exact: true, name: 'use_login', component: LoginView },
  // 内网环境都需要
  { path: '/mail/:viewType', exact: true, name: 'mail_login', component: MailLogin },
  { path: '/find', exact: true, name: 'find_user', component: FindView },
  {
    path: '/sys/',
    name: 'sys_main',
    component: SysMain,
    routes: [
      { name: 'main_admin', path: 'admin/', component: MainAdmin, routes: RouteForMain },
    ],
  },
  {
    path: '/main/',
    name: 'root',
    component: Main,
    routes: [
      { name: 'push_msg', path: 'tools/pus-msg', render: (props) => <PushMsg {...props} /> },
      { name: 'task_msg', path: 'tools/task-msg', render: (props) => <TaskMsg {...props} /> },
      { name: 'vpn_qr', path: 'tools/vpn', render: (props) => <VPNView {...props} /> },
      // { name: 'main_help', path: 'help/', component: MainHelp },
      // { name: 'demo_form', path: 'demo', render: (props) => <TestEditForm {...props} /> },
    ],
  },
  { name: 'not_found', path: '*', exact: true, component: NotFound },
];

export default routers;
